import React, { Component } from "react";
import Layout from "../components/Layout";
import Form from "../components/Form";

import Head from "../components/Head";
import { SeoPartners } from "./../data/SEO";

class PageIndex extends Component {
  render() {
    return (
      <Layout main>
        <Head
          Title={SeoPartners[0].title}
          Description={SeoPartners[0].description}
          isSiteName={true}
        />

        <section id="home" className="home py-5">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-5 mb-lg-0">
                <h1 className="display-4 mb-4 font-weight-extrabold text-blue">
                  Conviértase <br />
                  en Partner
                </h1>
                <p className="mb-4 pb-2 h5 line-height-16">
                  Tenemos más de 5 años de experiencia en Facturación
                  Electrónica, Olvídate de los problemas con SUNAT y sus
                  actualizaciones, nosotros nos encargamos de eso.
                </p>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="m-3">
                    <img
                      src={"assets/images/logos/python.svg"}
                      alt=""
                      width="80"
                    />
                  </div>
                  <div className="m-3">
                    <img
                      src={"assets/images/logos/net.svg"}
                      alt=""
                      width="80"
                    />
                  </div>
                  <div className="m-3">
                    <img
                      src={"assets/images/logos/java.svg"}
                      alt=""
                      width="80"
                    />
                  </div>
                  <div className="mx-3">
                    <img
                      src={"assets/images/logos/php.svg"}
                      alt=""
                      width="80"
                    />
                  </div>
                  <div className="mx-3">
                    <img src={"assets/images/logos/go.svg"} alt="" width="80" />
                  </div>
                  <div className="mx-3">
                    <img
                      src={"assets/images/logos/nodejs.svg"}
                      alt=""
                      width="80"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <Form title="Contáctenos" />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default PageIndex;
