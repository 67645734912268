import React, { Component } from "react";
import Layout from "../components/Layout";
import Featured from "../components/Featured";
import Characteristic from "../components/Characteristic";
import Advisory from "../components/Advisory";
import Developer from "../components/Developer";
import Plans from "../components/Plans";

import TinySlider from "tiny-slider-react";
import Client from "../components/Client";

import Head from "../components/Head";
import { SeoIndex } from "./../data/SEO";
import Form from "../components/Form";

class PageIndex extends Component {
  render() {
    const settingClient = {
      lazyload: true,
      nav: false,
      navPosition: "bottom",
      mouseDrag: true,

      //auto
      autoplay: true,
      controls: false,

      //botón stop
      autoplayButtonOutput: false,

      loop: true,
      items: 5,
      speed: 1500,
      autoplayTimeout: 3000,
    };

    return (
      <Layout main>
        <Head
          Title={SeoIndex[0].title}
          Description={SeoIndex[0].description}
          isSiteName={true}
        />

        <section id="home" className="home py-5">
          <div className="container position-relative">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-5 mb-lg-0">
                <h1 className="display-4 mb-4 font-weight-extrabold text-blue">
                  Solución en <br />
                  Facturación <br />
                  Electrónica
                </h1>
                <p className="mb-4 pb-2 h5 line-height-16">
                  Emite es una solución que contempla todos los requerimientos
                  establecidos por SUNAT y añade funcionalidades únicas en el
                  mercado para la satisfacción total de nuestros Clientes.
                </p>
                <div>
                  <a href="/#prices" className="btn btn-outline-primary">
                    Ver planes
                  </a>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <Form title="Te Asesoramos GRATIS" />
              </div>
            </div>
          </div>
        </section>

        <section className="pb-4">
          <div className="container">
            <div className="row text-center">
              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <Featured
                  Icon="icon-authorized"
                  Title="Autorizado"
                  Description="Somos PSE autorizados por SUNAT"
                />
              </div>

              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <Featured
                  Icon="icon-speed"
                  Title="Rapidez"
                  Description="Implementación inmediata, en menos de 24 horas"
                />
              </div>

              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <Featured
                  Icon="icon-management"
                  Title="Gestión"
                  Description="Más que sólo facturación electrónica"
                />
              </div>

              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <Featured
                  Icon="icon-server"
                  Title="Uptime 99%"
                  Description="Garantizamos el 99% de disponibilidad"
                />
              </div>

              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <Featured
                  Icon="icon-multiplatform"
                  Title="Multiplataforma"
                  Description="Muy pronto también en tablets y celulares"
                />
              </div>

              <div className="col-6 col-md-4 col-lg-2 mb-3">
                <Featured
                  Icon="icon-iso"
                  Title="Seguro"
                  Description="MContamos con la ISO 27001"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pb-lg-5">
          <div className="container py-5">
            <h2 className="text-center display-4 mb-4 mb-lg-5 font-weight-extrabold text-blue">
              Características
            </h2>

            <div className="row align-items-center">
              <div className="col-lg-7 mb-4 mb-lg-0">
                <img
                  src={"assets/images/dashboard.png"}
                  srcSet={"assets/images/dashboard@2x.png"}
                  className="img-fluid"
                  alt="Dashboard Emite"
                />
              </div>

              <div className="col-lg-5">
                <Characteristic
                  Icon="icon-docs"
                  Title="Validación de documentos"
                  Description="Los documentos antes de ser enviados a SUNAT pasan por un meticuloso proceso de validación garantizando."
                />

                <Characteristic
                  Icon="icon-settings"
                  Title="Integrado con sistemas externos"
                  Description="Consulta RUC, RENIEC y tipos de cambio de fuentes oficiales."
                />

                <Characteristic
                  Icon="icon-updates"
                  Title="Actualizaciones constantes"
                  Description="No solo actualizamos por normativas, constantemente mejoramos las funcionalidades internas sin costo alguno."
                />

                <Characteristic
                  Icon="icon-interface"
                  Title="Interfaz intuitiva"
                  Description="Navega por la aplicación con mucha facilidad a través de una interfaz amigable y fácil de usar."
                />

                <Characteristic
                  Icon="icon-users"
                  Title="Gestión de perfiles y usuarios"
                  Description="Los documentos antes de ser enviados a SUNAT pasan por un meticuloso proceso de validación garantizando."
                />

                <Characteristic
                  Icon="icon-making"
                  Title="Toma de decisiones"
                  Description="Emite no solo te ayuda a cumplir con las normativas, es tu mejor aliado para la analítica de tu negocio."
                />
              </div>
            </div>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 mb-5 mb-lg-0">
                <h2 className="display-4 font-weight-extrabold text-blue">
                  Reportes <br />
                  en tu móvil
                </h2>
                <p>
                  Con Emite podrás ver tus reportes desde la comodidad de tu
                  celular a través del App en Android o iOS.
                </p>
                <p>
                  Visualizarás reportes, estadísticas, etc. todo de forma fácil
                  y sencilla sin tener que perder el control de tu negocio.
                </p>
                <div className="d-flex mt-4 mt-lg-5">
                  <div className="mr-4">
                    <img
                      src={"assets/images/appstore.svg"}
                      className="img-fluid"
                      alt="App Store Emite Próximamente"
                    />
                  </div>
                  <div>
                    <img
                      src={"assets/images/playstore.svg"}
                      className="img-fluid"
                      alt="Play Store Emite Próximamente"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-7 bg-mobile">
                <div className="py-5 text-center">
                  <img
                    src={"assets/images/mobileapp.svg"}
                    className="img-fluid"
                    alt="Mobile App Emite"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="prices">
          <div className="container py-5">
            <h2 className="text-center display-4 mb-4 mb-lg-5 pb-lg-4 font-weight-extrabold text-blue">
              Precios
            </h2>

            <Plans />

            <p className="my-5 pt-lg-5 pb-4 text-center">
              <a href="/prices" className="btn btn-secondary px-5">
                <span className="px-3">Ver tabla comparativa</span>
              </a>
            </p>

            <Developer />
          </div>
        </section>

        <section className="emite py-lg-5">
          <div className="container pb-5 pt-lg=5">
            <div className="row align-items-center py-5">
              <div className="col-lg-6">
                <img
                  src={"assets/images/app.png"}
                  className="img-fluid"
                  alt="Acerca de Emite"
                />
              </div>
              <div className="col-lg-6">
                <p className="mb-5">
                  <img
                    src={"assets/images/logo.svg"}
                    className="img-fluid"
                    alt="Logo Emite"
                  />
                </p>
                <p>
                  Te brinda una solución tecnológica que permite emitir
                  comprobantes electrónicos interactuando directamente con
                  SUNAT, permitiendo a nuestros emisores y receptores obtener
                  respuestas inmediatas de la aprobación y/o rechazo de sus
                  comprobantes.
                </p>
                <p>
                  Es una solución que contempla todos los requerimientos
                  establecidos por SUNAT y añade funcionalidades únicas en el
                  mercado para la satisfacción total de nuestros clientes.
                </p>
              </div>
            </div>
          </div>
        </section>

        <Advisory />

        <div className="testimonials bg-gray py-5">
          <div className="container pt-5">
            <h2 className="text-center h1 mb-4 mb-lg-5 font-weight-extrabold text-blue">
              Más de 100 clientes confían en nosotros
            </h2>

            <TinySlider settings={settingClient}>
              <Client Image="assets/images/clients/toolcraft.png" />
              <Client Image="assets/images/clients/cardiomed-peru.png" />
              <Client Image="assets/images/clients/stranssantos.png" />
              <Client Image="assets/images/clients/titan-fashion.png" />
              <Client Image="assets/images/clients/elcisne.png" />
              <Client Image="assets/images/clients/inversiones-puritacalidad.png" />
              <Client Image="assets/images/clients/pm-servicios.png" />
              <Client Image="assets/images/clients/fabri-medical.png" />
              <Client Image="assets/images/clients/labsystems.png" />
              <Client Image="assets/images/clients/lassac.png" />
              <Client Image="assets/images/clients/servitest.png" />
              <Client Image="assets/images/clients/pce-peru.png" />
              <Client Image="assets/images/clients/halema.png" />
              <Client Image="assets/images/clients/ventura-import.png" />
              <Client Image="assets/images/clients/illa-hotel.png" />
            </TinySlider>
          </div>
        </div>
      </Layout>
    );
  }
}

export default PageIndex;
