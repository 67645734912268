const Year = new Date().getFullYear();

const Config = {
  siteName: "Emite",
  siteLogo: "/assets/images/logo.svg",
  siteLogoFooter: "/assets/images/logo-white.svg",
  siteCopyright: `© ${Year} Emite.pe`,
  siteLegal: "Todos los derechosreservados.",
  siteSlogan: "Soluciones en facturación electrónica",
  numberWhatsapp: "949534411",
  numberPhone1: "(+51) 01 744-7577",
  numberPhone2: "(+51) 01 744-7626",
  siteEmail: "info@emite.pe",
  siteLocation: "Av. Nicolás Arriola 314 Of 503 B, Santa Catalina, Lima - Perú",
  siteDescription: "La mejor solución en facturación electrónica",
  plan: [
    {
      data: "facturador",
      icon: "icon-facturador",
      title: "Facturador",
      description: "Realiza emisiones de facturas de forma automática",
      featured: ["1 Usuario", "Facturación"],
      price: "29",
      link: "http://emite.pe/1",
      activate: "",
    },
    {
      data: "emprende",
      icon: "icon-emprende",
      title: "Smart Emprende",
      description: "Realiza emisiones de facturas de forma automática",
      featured: ["2 Usuarios", "Facturación", "POS"],
      price: "70",
      link: "http://emite.pe/2",
      activate: "499",
    },
    {
      data: "despega",
      icon: "icon-despega",
      title: "Smart Despega",
      description: "Realiza emisiones de facturas de forma automática",
      featured: ["3 Usuarios", "Facturación", "POS", "Cobranzas"],
      price: "99",
      link: "http://emite.pe/3",
      activate: "499",
    },
    {
      data: "vuela",
      icon: "icon-vuela",
      title: "Smart Vuela",
      description: "Realiza emisiones de facturas de forma automática",
      featured: [
        "4 Usuarios",
        "Facturación",
        "POS",
        "Cobranzas",
        "Compras e inventarios",
      ],
      price: "149",
      link: "http://emite.pe/4",
      activate: "499",
    },
  ],
  shareLinks: [
    {
      url: "https://www.facebook.com/emite.pe",
      iconClassName: "icon-facebook",
      bgClassName: "bg-facebook",
    },
    {
      url: "/#",
      iconClassName: "icon-twitter",
      bgClassName: "bg-twitter",
    },
    {
      url: "/#",
      iconClassName: "icon-instagram",
      bgClassName: "bg-instagram",
    },
    {
      url: "/#",
      iconClassName: "icon-youtube",
      bgClassName: "bg-youtube",
    },
    {
      url: "/#",
      iconClassName: "icon-pinterest",
      bgClassName: "bg-pinterest",
    },
  ],
};

module.exports = Config;
