import React, { Component, Fragment } from "react";
import Config from "./../data/Data";
import Share from "./Share";

class Footer extends Component {
  render() {
    const {
      siteCopyright,
      siteDescription,
      numberWhatsapp,
      siteLegal,
      siteEmail,
      siteLocation,
      numberPhone1,
      numberPhone2,
      siteLogoFooter,
      siteName,
    } = Config;

    const formatWhatapp = numberWhatsapp
      .toString()
      .replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ");

    return (
      <Fragment>
        <footer id="footer">
          <div className="container">
            <div className="container py-5 py-lg-2">
              <div className="row py-lg-5">
                <div className="col-lg-3 mb-5 mb-lg-0">
                  <p className="mb-4 pb-2">
                    <img src={siteLogoFooter} alt={siteName} />
                  </p>
                  <p>{siteDescription}</p>
                </div>

                <div className="col-lg-4 mb-5 mb-lg-0 offset-lg-1">
                  <h3 className="h5 mb-3 font-weight-bold">Planes</h3>
                  <ul className="list-unstyled mb-0">
                    <li className="py-2">
                      <a href="/prices">Facturador</a>
                    </li>
                    <li className="py-2">
                      <a href="/prices">Smart Emprende</a>
                    </li>
                    <li className="py-2">
                      <a href="/prices">Smart Despega</a>
                    </li>
                    <li className="py-2">
                      <a href="/prices">Smart Vuela</a>
                    </li>
                    <li className="py-2">
                      <a href="/prices">Integrador</a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4">
                  <h3 className="h5 mb-3 font-weight-bold">Redes sociales</h3>
                  <ul className="list-unstyled mb-4 pb-2 d-flex">
                    <Share />
                  </ul>

                  <h3 className="h6 font-weight-bold">Teléfonos:</h3>
                  <p className="mb-4">
                    {numberPhone1} <span className="mx-2">|</span>{" "}
                    {numberPhone2}
                    <br />
                    <a
                      href={`https://wa.me/51${numberWhatsapp}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      +51 {formatWhatapp}
                    </a>
                    <br />
                  </p>

                  <h3 className="h6 mb-4">
                    <strong>Email</strong>:{" "}
                    <a href={`mailto:${siteEmail}`}>{siteEmail}</a>
                  </h3>

                  <h3 className="h6 font-weight-bold">Dirección:</h3>
                  <p className="mb-0">{siteLocation}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="border-top border-gray py-4 small text-center text-lg-left">
            <div className="container">
              <div className="d-lg-flex justify-content-between flex-row-reverse">
                <ul className="list-unstyled d-flex mb-2 mb-lg-0 pb-1 pb-lg-0 justify-content-center">
                  <li>
                    <a href="/#" className="">
                      Política de privacidad
                    </a>
                  </li>
                  <li className="ml-4">
                    <a href="/#" className="">
                      Términos y condiciones
                    </a>
                  </li>
                </ul>
                <p className="mb-0 d-md-flex flex-row-reverse justify-content-center">
                  <span className="d-block">
                    <span className="d-none d-md-inline-block px-1">-</span>
                    {siteLegal}
                  </span>
                  <span className="d-block">{siteCopyright}</span>
                </p>
              </div>
            </div>
          </div>
        </footer>
        <div className="menu-shadow"></div>
      </Fragment>
    );
  }
}

export default Footer;
