import React from "react";

const Form = (props) => {
  const { title } = props;

  return (
    <div className="bg-white p-4 p-lg-5 rounded shadow">
      <div className="p-2 p-lg-0">
        <h2 className="mb-4 font-weight-extrabold text-blue">{title}</h2>
        <form action="">
          <div className="form-group">
            <label htmlFor="form-name">Nombre y apellidos: *</label>
            <input type="text" id="form-name" className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="form-name">Correo electrónico: *</label>
            <input type="text" id="form-name" className="form-control" />
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="form-name">Número de celular: *</label>
                <input type="text" id="form-name" className="form-control" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label htmlFor="form-name">Número de RUC: *</label>
                <input type="text" id="form-name" className="form-control" />
              </div>
            </div>
          </div>
          <div className="form-group small">
            <label htmlFor="accept">
              <input type="checkbox" name="" id="accept" className="mr-2" />
              He leído y acepto la{" "}
              <a href="/#" target="_blank" className="text-primary">
                Política de Privacidad
              </a>{" "}
              y los{" "}
              <a href="/#" target="_blank" className="text-primary">
                Términos y Condiciones
              </a>
              .
            </label>
          </div>
          <div className="form-group mb-0">
            <input
              type="submit"
              className="btn btn-lg btn-primary btn-block"
              value="Contactar"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
