import React from "react";
import Layout from "../components/Layout";

import Head from "../components/Head";
import { SeoPrices } from "../data/SEO";
import Advisory from "../components/Advisory";

import Developer from "../components/Developer";
import Config from "./../data/Data";

const PagePrices = () => {
  const { plan } = Config;

  return (
    <Layout>
      <Head Title={SeoPrices[0].title} Description={SeoPrices[0].description} />

      <div className="py-5">
        <div className="container">
          <h1 className="display-4 font-weight-extrabold text-blue text-center mb-5 pb-lg-5">
            Tabla comparativa de precios
          </h1>

          <div className="mx-n3 mx-lg-0 pl-3 pl-lg-0">
            <div className="d-flex mb-5 pb-3 prices">
              <div className="description">
                <div className="display-5 my-2 hpx-58"></div>

                <div className="font-weight-bold small text-uppercase text-blue">
                  &nbsp;
                </div>
                <div className="h4 text-blue font-weight-extrabold mt-n2">
                  &nbsp;
                </div>
                <div className="small mt-n3">&nbsp;</div>

                <div>Usuarios incluidos</div>
                <div>Cajas incluidas en POS</div>
                <div>Precio por cada usuario adicional</div>
                <div>Precio por POS adicional (incluye 2 usuarios)</div>

                <div className="h5 mt-3 mb-0 hpx-50">&nbsp;</div>

                <div className="h5 d-flex align-items-center text-uppercase text-red font-weight-bold mt-4 mb-0">
                  <i className="icon-ventas mr-1"></i>
                  <span className="pt-1">Ventas</span>
                </div>

                <div>
                  Facturas, boletas, notas de crédito y débito, anulaciones
                </div>
                <div>Guías de remisión</div>
                <div>Cotizaciones electrónicas</div>
                <div>Ordenes de venta electrónicas</div>
                <div>Registro marcas, categorías de productos / servicios</div>
                <div>Registro clientes</div>
                <div>Multimoneda</div>
                <div>Multi unidades de medida</div>
                <div>Pagos a crédito</div>
                <div>Lista de comprobantes</div>
                <div>Reportes</div>

                <div className="h5 d-flex align-items-center text-uppercase text-red font-weight-bold mt-4 mb-0">
                  <i className="icon-pos mr-1"></i>
                  <span className="pt-1">POS</span>
                </div>

                <div>Facturas, boletas, notas de crédito, anulaciones</div>
                <div>Gestión de cajas</div>
                <div>Gestión de turnos</div>
                <div>Gestion de pedidos</div>
                <div>Registro marcas, categorias de productos / servicios</div>
                <div>Registro clientes</div>
                <div>Multi unidades de medida</div>
                <div>Reportes</div>

                <div className="h5 d-flex align-items-center text-uppercase text-red font-weight-bold mt-4 mb-0">
                  <i className="icon-cobranzas mr-1"></i>
                  <span className="pt-1">Cobranzas</span>
                </div>

                <div>Cuentas bancarias</div>
                <div>Conciliación de facturas</div>
                <div>Estados de cuenta</div>
                <div>Cuentas por cobrar</div>
                <div>Reportes</div>

                <div className="h5 d-flex align-items-center text-uppercase text-red font-weight-bold mt-4 mb-0">
                  <i className="icon-compras mr-1"></i>
                  <span className="pt-1">Compras e Inventarios</span>
                </div>

                <div>Solicitudes de compra</div>
                <div>Ordenes de compra</div>
                <div>Entrada de mercadería</div>
                <div>Devolución de mercadería (próxima actualización)</div>
                <div>Factura de proveedor</div>
                <div>Nota de crédito proveedor (próxima actualización)</div>
                <div>Kárdex</div>
                <div>Registro marcas, categorías de productos / servicios</div>
                <div>Registro de proveedores</div>
                <div>Reportes</div>

                <div className="h5 d-flex align-items-center text-uppercase text-red font-weight-bold mt-4 mb-0">
                  <i className="icon-config mr-1"></i>
                  <span className="pt-1">CONFIGURACIÓN</span>
                </div>

                <div>Gestion de usuario y perfiles</div>
                <div>Monedas</div>
                <div>Tipos de cambio</div>

                <div className="mt-3 hpx-50">&nbsp;</div>
              </div>

              <div
                data-content="facturador"
                className="tab-content item biller"
              >
                <div className="display-5 text-lightblue my-2">
                  <i className="icon-facturador"></i>
                </div>
                <div className="font-weight-bold small text-uppercase text-blue">
                  {plan[0].title}
                </div>
                <div className="h4 text-blue font-weight-extrabold mt-n2">
                  <span className="h5 font-weight-bold mb-0 mr-2">Desde</span>{" "}
                  S/ {plan[0].price}
                </div>
                <div className="small mt-n3">&nbsp;</div>

                <div>&nbsp;</div>
                <div>1</div>
                <div>S/ 24.90</div>
                <div>&nbsp;</div>

                <div className="mt-3">
                  <div className="w-100">
                    <a
                      href={plan[0].link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-sm btn-auto btn-outline-primary btn-block"
                    >
                      Comprar
                    </a>
                  </div>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[0].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[0].title}
                </div>

                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[0].title}
                </div>

                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[0].title}
                </div>

                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[0].title}
                </div>

                <div>&nbsp;</div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-3">
                  <div className="w-100">
                    <a
                      href={plan[0].link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-sm btn-auto btn-outline-primary btn-block"
                    >
                      Comprar
                    </a>
                  </div>
                </div>
              </div>

              <div
                data-content="emprende"
                className="tab-content item undertake"
              >
                <div className="display-5 text-lightblue my-2">
                  <i className="icon-emprende"></i>
                </div>
                <div className="font-weight-bold small text-uppercase text-blue">
                  {plan[1].title}
                </div>
                <div className="h4 text-blue font-weight-extrabold mt-n2">
                  S/ {plan[1].price}{" "}
                  <span className="h5 font-weight-bold mb-0 ml-2">/mes</span>
                </div>
                <div className="small mt-n3">
                  Activación S/ {plan[1].activate}
                </div>

                <div>1</div>
                <div>2</div>
                <div>S/ 34.90</div>
                <div>S/ 69.80</div>

                <div className="mt-3">
                  <div className="w-100">
                    <a
                      href={plan[1].link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-sm btn-auto btn-outline-primary btn-block"
                    >
                      Comprar
                    </a>
                  </div>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[1].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[1].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[1].title}
                </div>

                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[1].title}
                </div>

                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[1].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-3">
                  <div className="w-100">
                    <a
                      href={plan[1].link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-sm btn-auto btn-outline-primary btn-block"
                    >
                      Comprar
                    </a>
                  </div>
                </div>
              </div>

              <div
                data-content="despega"
                className="tab-content item takesoff active"
              >
                <div className="most p-0 d-flex">Más vendido</div>
                <div className="display-5 text-lightblue my-2">
                  <i className="icon-despega"></i>
                </div>
                <div className="font-weight-bold small text-uppercase text-blue">
                  {plan[2].title}
                </div>
                <div className="h4 text-red font-weight-extrabold mt-n2">
                  S/ {plan[2].price}
                  <span className="h5 font-weight-bold mb-0 ml-2">/mes</span>
                </div>
                <div className="small mt-n3">
                  Activación S/ {plan[2].activate}
                </div>

                <div>1</div>
                <div>3</div>
                <div>S/ 39.90</div>
                <div>S/ 79.80</div>

                <div className="mt-3">
                  <div className="w-100">
                    <a
                      href={plan[2].link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-sm btn-auto btn-outline-primary btn-block"
                    >
                      Comprar
                    </a>
                  </div>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[2].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[2].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[2].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[2].title}
                </div>

                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[2].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-3">
                  <div className="w-100">
                    <a
                      href={plan[2].link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-sm btn-auto btn-outline-primary btn-block"
                    >
                      Comprar
                    </a>
                  </div>
                </div>
              </div>

              <div data-content="vuela" className="tab-content item flying">
                <div className="display-5 text-lightblue my-2">
                  <i className="icon-vuela"></i>
                </div>
                <div className="font-weight-bold small text-uppercase text-blue">
                  {plan[3].title}
                </div>
                <div className="h4 text-blue font-weight-extrabold mt-n2">
                  S/ {plan[3].price}
                  <span className="h5 font-weight-bold mb-0 ml-2">/mes</span>
                </div>
                <div className="small mt-n3">
                  Activación S/ {plan[3].activate}
                </div>

                <div>2</div>
                <div>4</div>
                <div>S/ 54.90</div>
                <div>S/ 109.80</div>

                <div className="mt-3">
                  <div className="w-100">
                    <a
                      href={plan[3].link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-sm btn-auto btn-outline-primary btn-block"
                    >
                      Comprar
                    </a>
                  </div>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[3].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[3].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[3].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[3].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-4 mb-0 font-weight-bold small text-uppercase text-blue">
                  {plan[3].title}
                </div>

                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>
                <div>
                  <i className="icon-check"></i>
                </div>

                <div className="mt-3">
                  <div className="w-100">
                    <a
                      href={plan[3].link}
                      target="_blank"
                      rel="noreferrer noopener"
                      className="btn btn-sm btn-auto btn-outline-primary btn-block"
                    >
                      Comprar
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Developer />
        </div>
      </div>

      <Advisory />
    </Layout>
  );
};

export default PagePrices;
