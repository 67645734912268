import React from "react";
import { Switch, Route } from "react-router-dom";

import PageIndex from "./pages/Index";
import PagePrices from "./pages/Prices";
import PagePartners from "./pages/Partners";
import Page404 from "./pages/error404";

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={PageIndex} />
      <Route path="/prices" component={PagePrices} />
      <Route path="/partners" component={PagePartners} />
      <Route path="*" component={Page404} />
    </Switch>
  );
};

export default Routes;
