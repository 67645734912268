import React from "react";

const Developer = () => {
  return (
    <div className="bg-white d-md-flex partners rounded p-4 justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div className="text-red h1 mb-0 ml-n2 pt-1">
          <span className="icon-question"></span>
        </div>
        <p className="mb-0 line-height-15 ml-2">
          ¿Eres un desarrollador independiente? ó ¿tienes una solución que
          desees integrar con Facturación Electrónica?...
          <strong>
            <em>No te preocupes tenemos una solución ideal para tí.</em>
          </strong>
        </p>
      </div>
      <div className="pl-lg-5 mt-4 mt-lg-0 text-center">
        <a href="/#" className="btn btn-outline-primary">
          Ir a Partners
        </a>
      </div>
    </div>
  );
};

export default Developer;
