import React from "react";
import Layout from "../components/Layout";
import { withRouter } from "react-router-dom";

import Head from "../components/Head";
import { SeoError } from "./../data/SEO";

export default withRouter(({ history }) => {
  return (
    <Layout>
      <Head Title={SeoError[0].title} Description={SeoError[0].description} />

      <div className="container py-5">
        <div className="row py-5 align-items-center">
          <div className="col-lg-6">
            <h1>Página no encontrada</h1>
            <p className="mb-5">
              Esto puede deberse porque la página no existe
              <br />o la ruta ha sido cambiado por una nueva.
            </p>

            <h2 className="h5 mb-3">También puede:</h2>

            <ul>
              <li>
                <a href="/#" onClick={() => history.goBack()}>
                  Ir atrás
                </a>
              </li>
              <li>
                <a href="/">Ir a la página de inicio</a>
              </li>
              <li>
                <a href="/#footer">Contactarnos</a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <img
              src={"/assets/images/error404.svg"}
              className="mx-auto"
              alt=""
            />
          </div>
        </div>
      </div>
    </Layout>
  );
});
