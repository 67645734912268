import React, { useEffect } from "react";
import Config from "./../data/Data";
import Menu from "../components/Menu";

const Header = (props) => {
  const { siteLogo, siteName, siteSlogan } = Config;

  const handleScroll = () => {
    const offset = window.scrollY;
    const header = document.querySelector("header");

    if (offset > 200) {
      header.style.height = "70px";
      header.classList.add("active");
    } else {
      header.style.height = "90px";
      header.classList.remove("active");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <header className="py-3 py-lg-4">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="logo">
          <a href="/">
            <img
              src={siteLogo}
              alt={`${siteName} - ${siteSlogan}`}
              height="60"
            />
          </a>
        </div>

        <Menu />
      </div>
    </header>
  );
};

export default Header;
